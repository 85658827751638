
  import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxEditing,
    DxSorting,
  } from 'devextreme-vue/data-grid';
  import { DxToast } from 'devextreme-vue/toast';
  import DxSelectBox from 'devextreme-vue/select-box';
  import Button from 'devextreme-vue/button';

  import DocumentService from '../services/document-service';
  import EquipmentService from '../services/equipment-service';
  import EquipmentDocumentService from '../services/equipment-document-service';
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxPaging,
      DxFilterRow,
      DxSearchPanel,
      DxScrolling,
      DxPager,
      DxSelection,
      DxToast,
      DxSelectBox,
      Button,
      DxEditing,
      DxSorting,
    },
    data() {
      return {
        documents: null,
        dataSourceDocuments: null,
        dataSourceEquipmentAvailable: null,
        dataSourceEquipmentAssigned: null,
        allowedPageSizes: [5, 10, 20, 50, 'all'],
        showInfo: true,
        showNavButtons: true,
        isVisible: false,
        message: '',
        type: 'info',
        mode: 'select',
        selectedRowsData: [],
        i: 0,
      };
    },
    created() {
      this.documentService = new DocumentService();
      this.equipmentService = new EquipmentService();
      this.equipmentDocumentService = new EquipmentDocumentService();
    },
    mounted() {
      this.documentService.getDocumentosCombo().then(data => this.dataSourceDocuments=data);  
    },
    computed: {
      selectedRowKeys() {
        return this.selectedRowsData.map((data) => data.id_equipo_);
      },
    },
    methods: {
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
      addDocument() {
        for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
            this.equipmentDocumentService.createEquipoDocumento({"id_documento": this.documents.id_documento, "id_equipo": this.selectedRowsData[this.i].id_equipo}).then(() => {           
              
              if (this.i==this.selectedRowsData.length) {
                this.equipmentDocumentService.getEquipoDisponiblesPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentAvailable=data);
                this.equipmentDocumentService.getEquipoAsignadosPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentAssigned=data);
              }
              
            });
        }
      },
      onSelectionChanged({ selectedRowsData }) {
        this.selectedRowsData = selectedRowsData;
      },
      valueChangedDocument() {
        this.equipmentDocumentService.getEquipoDisponiblesPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentAvailable=data);
        this.equipmentDocumentService.getEquipoAsignadosPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentAssigned=data);
      },
      deleteEquipment(e) {
        this.equipmentDocumentService.deleteEquipoDocumento(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          this.equipmentDocumentService.getEquipoDisponiblesPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentAvailable=data);
          this.equipmentDocumentService.getEquipoAsignadosPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentAssigned=data);
        });
      },
    },  
  };
